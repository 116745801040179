<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="IO hinzufügen"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew(null)"
          />
          <Button
            label="IO löschen"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="io_data"
        v-model:selection="selectedIOs"
        dataKey="_id"
        :loading="loading"
        :paginator="true"
        :rows="50"
        v-model:filters="filters"
        filterDisplay="row"
        :globalFilterFields="['metadata.name', 'metadata.description', 'value.unit', 'metadata.sensor', 'metadata.category', 'metadata.department', 'value.type']"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} IO-Definitionen"
        responsiveLayout="scroll"
        :getCategory="getCategory"
      >
        <template #empty> Keine IO-Einträge gefunden...</template>
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div class="table-header flex justify-content-between">
            <h5 class="mb-2 m-md:0 as-md-center">ENAT Module verwalten</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="suche Eintrag..." />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="metadata.icon" header="Icon" :sortable="false">
          <template #body="slotProps">
            <Button
              v-if="slotProps.data.metadata.icon"
              :icon="slotProps.data.metadata.icon"
              class="p-button-rounded p-button-primary mr-2"
            />
          </template>
        </Column>
        <Column field="metadata.category" header="Kategorie" :sortable="true" filterField="metadata.category" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ getCategory(slotProps.data.metadata.category) }}</span>
          </template>
          <template v-if="config_io !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="config_io.types_category" optionLabel="label" optionValue="value" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ slotProps.option.label }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="metadata.name" header="Name" :sortable="true" filterField="metadata.name">
          <template v-if="config_io !== null && fullSearch === true" #filter="{filterModel, filterCallback}">
              <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="metadata.description" header="Beschreibung" :sortable="true" filterField="metadata.description">
          <template v-if="config_io !== null && fullSearch === true" #filter="{filterModel, filterCallback}">
              <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="metadata.department" header="Anlage" :sortable="true" filterField="metadata.department" :showFilterMenu="false" >
          <template v-if="config_io !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="config_io.types_department" optionLabel="value" optionValue="value" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ slotProps.option.value }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="source.parameter" header="Datenquelle" :sortable="true" filterField="source.parameter">
          <template v-if="config_io !== null && fullSearch === true" #filter="{filterModel, filterCallback}">
              <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="value.type" header="IO-Art" :sortable="true" filterField="value.type" :showFilterMenu="false" >
          <template v-if="config_io !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="config_io.types_io" optionLabel="value" optionValue="value" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ slotProps.option.value }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="value.unit" header="Einheit" :sortable="true" filterField="value.unit">
          <template v-if="config_io !== null && fullSearch === true" #filter="{filterModel, filterCallback}">
            <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="config_io.types_unit" optionLabel="label" optionValue="value" placeholder="Alle" class="p-column-filter">
                <template #option="slotProps">
                    <div class="p-multiselect-representative-option">
                        <p>{{ slotProps.option.label }}</p>
                    </div>
                </template>
            </MultiSelect>
          </template>
        </Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-info mr-2"
              @click="editProduct(slotProps.data)"
            />
            <Button
              icon="pi pi-copy"
              class="p-button-rounded p-button-secondary mr-2"
              @click="openNew(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeleteProduct(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="productDialog"
      :style="{ width: '450px' }"
      header="IO Konfiguration"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="formgrid grid">
        <div class="field col">
          <div class="field text-center">
            <span :class="ioDialogData.metadata.icon + ' mif-5x'"></span>
          </div>
        </div>
        <div class="field col">
          <div
            class="field text-center"
            v-if="ioDialogData.value.value !== undefined"
          >
            <h2 class="mb-0">
              {{ ioDialogData.value.value
              }}<small>{{ " " + ioDialogData.value.unit }}</small>
            </h2>
            <small class="fg-lightSteel">[ {{ ioDialogData.value.valueRaw }} ]</small>
          </div>
          <div class="field text-center" v-else>
            <h2>???<small>???</small></h2>
          </div>
        </div>
      </div>

        <div class="formgrid grid">
        <div class="field col">
          <label for="name">Name</label>
          <InputText
            id="name"
            v-model.trim="ioDialogData.metadata.name"
            required="true"
            autofocus
            :class="'inputfield' + { invalid: submitted && !ioDialogData.metadata.name }"
          />
          <small class="error" v-if="submitted && !ioDialogData.metadata.name"
            >Name <b>muss</b> ausgefüllt werden!</small
          >
        </div>
        </div>
        <div class="field">
          <label for="description">Beschreibung</label>
          <Textarea
            id="description"
            v-model="ioDialogData.metadata.description"
            required="true"
            rows="3"
            cols="20"
          />
        </div>

        <div class="field">
          <label for="categoryStatus" class="mb-3">Kategorie</label>
          <Dropdown
            id="categoryStatus"
            v-model="ioDialogData.metadata.category"
            :options="config_io.types_category"
            optionLabel="label"
            optionValue="value"
            placeholder="Wetterstation, Heizung,..."
          >
          </Dropdown>
        </div>

        <div class="field">
          <label for="departmentStatus" class="mb-3">Anlage</label>
          <Dropdown
            id="departmentStatus"
            v-model="ioDialogData.metadata.department"
            :options="config_io.types_department"
            optionLabel="value"
            optionValue="value"
            placeholder="Heizhaus, Gewächshaus,..."
          >
            <template #option="slotProps">
              <div>
                <span>{{
                  "[" +
                  slotProps.option.value +
                  "]" +
                  " " +
                  slotProps.option.label
                }}</span>
              </div>
            </template>
          </Dropdown>
        </div>

        <div class="field">
          <label for="inventoryStatus" class="mb-3">Variablentyp</label>
          <Dropdown
            id="inventoryStatus"
            v-model="ioDialogData.value.type"
            :options="config_io.types_io"
            optionLabel="label"
            optionValue="value"
            placeholder="Digital, Analog,..."
          >
            <!-- <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' + slotProps.value.value">{{
                slotProps.value.label
              }}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span
                :class="'product-badge status-' + slotProps.value.toLowerCase()"
                >{{ slotProps.value }}</span
              >
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template> -->
          </Dropdown>
        </div>

        <div class="field">
          <label for="parameterObject" class="mb-3">Zugeordnetes Parameterobjekt</label>
          <Dropdown
            id="parameterObject"
            v-model="ioDialogData.source.parameter"
            :options="io_type"
            optionLabel="label"
            optionValue="key"
            placeholder="ventsController,..."
          >
            <!-- <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' + slotProps.value.value">{{
                slotProps.value.label
              }}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span
                :class="'product-badge status-' + slotProps.value.toLowerCase()"
                >{{ slotProps.value }}</span
              >
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template> -->
          </Dropdown>
        </div>

        <div class="formgrid grid">
          <div class="field col">
            <label for="price">Einheit</label>
            <Dropdown
              id="unit"
              v-model="ioDialogData.value.unit"
              :options="config_io.types_unit"
              optionLabel="label"
              optionValue="label"
            >
              <template #value="slotProps">
                <div>
                  <span> {{ slotProps.value }}</span>
                </div>
              </template>
              <template #option="slotProps">
                <div>
                  <span>{{ slotProps.option.label }}</span>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field col">
            <label for="price">Faktor</label>
            <Dropdown
              id="factor"
              v-model="ioDialogData.value.factor"
              :options="io_value_factor"
              optionLabel="label"
              optionValue="value"
            >
              <template #value="slotProps">
                <div>
                  <span> {{ slotProps.value }}</span>
                </div>
              </template>
              <template #option="slotProps">
                <div>
                  <span>{{ slotProps.option.label }}</span>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field col">
            <label for="icon">Icon</label>
            <Dropdown
              id="icon"
              v-model="ioDialogData.metadata.icon"
              :filter="true"
              placeholder="Icon wählen"
              :options="config_io.types_icons"
              optionLabel="label"
              optionValue="label"
            >
              <template #value="slotProps">
                <div>
                  <i :class="slotProps.value" />
                  <span class="text-small"> [{{ slotProps.value }}]</span>
                </div>
              </template>

              <template #option="slotProps">
                <div>
                  <i :class="slotProps.option.icon" class="text-3xl"/>
                  <span class="text-small">
                    [{{ slotProps.option.label }}]</span
                  >
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="field">
          <label class="mb-3">IO-Art</label>
          <div class="formgrid grid">
            <div class="field-radiobutton col-6">
              <RadioButton
                id="type_opcua"
                name="type"
                value="opcua"
                v-model="ioDialogData.source.type"
              />
              <label for="type_opcua">OPC-UA</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="type_virtual"
                name="type"
                value="virtual"
                v-model="ioDialogData.source.type"
              />
              <label for="type_virtual">Virtueller Wert</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="type_external"
                name="type"
                value="external"
                v-model="ioDialogData.source.type"
              />
              <label for="type_external">Externer Wert</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="type_fixed"
                name="type"
                value="fixed"
                v-model="ioDialogData.source.type"
              />
              <label for="type_fixed">Fixer Wert</label>
            </div>
          </div>
        </div>

        <div v-if="ioDialogData.source.type === 'opcua'">
          <div class="field">
            <label for="serverStatus" class="mb-3">Server</label>
            <Dropdown
              id="serverStatus"
              v-model="ioDialogData.source.server"
              :options="config_io.types_server"
              optionLabel="label"
              optionValue="label"
              placeholder="OPC-UA Server Eintrag"
              @change="onServerChange()"
            >
            </Dropdown>
          </div>

          <div v-if="false" class="formgrid grid">
            <div class="field col">
              <label for="quantity">Server URL (opc.tcp://IP:PORT)</label>
              <InputText id="endpoint" v-model="ioDialogData.nodePath" />
            </div>
          </div>

          <div v-if="false" class="formgrid grid">
            <div class="field col">
              <label>Variablenauswahl</label>
              <Dropdown
                id="opcBrowse"
                v-model="ioDialogData.metadata.sensor"
                :options="opc_browse"
                optionLabel="label"
                optionValue="label"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <span class="text-small">{{ slotProps.value }}</span>
                  </div>
                  <div v-else>
                    <span class="text-small">keine Variable definiert!</span>
                  </div>
                </template>

                <template #option="slotProps">
                  <div>
                    <span class="text-small">{{ slotProps.option.label }}</span>
                  </div>
                </template>
              </Dropdown>
            </div>
          </div>

          <div class="field">
            <label for="description">OPC-UA Verzeichnis</label>
            <Tree
              :value="opcTree"
              :loading="!opcTree"
              selectionMode="single"
              :expandedKeys="opcTreeExpandedKeys"
              v-model:selectionKeys="opcTreeSelection"
              @node-select="onOpcTreeSelect"
              scrollHeight="300px"
            ></Tree>
          </div>

          <div class="formgrid grid">
            <div class="field col">
              <label>Pfad (OPC-UA)</label>
              <InputText
                id="path"
                v-model="ioDialogData.nodeId"
                class="inputtext-sm"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="field">
          <label class="mb-3">Messwert darstellen als</label>
          <div class="formgrid grid">
            <div class="field-radiobutton col-6">
              <RadioButton
                id="datatypeInteger"
                name="datatype"
                value="integer"
                v-model="ioDialogData.value.datatype"
              />
              <label for="datatypeInteger">Integer</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="datatypeFloat"
                name="datatype"
                value="float"
                v-model="ioDialogData.value.datatype"
              />
              <label for="datatypeFloat">Float</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="datatypeDatetime"
                name="datatype"
                value="datetime"
                v-model="ioDialogData.value.datatype"
              />
              <label for="datatypeDatetime">DateTime</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="datatypeDayTime"
                name="datatype"
                value="daytime"
                v-model="ioDialogData.value.datatype"
              />
              <label for="datatypeDatetime">DayTime</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="datatypeBool"
                name="datatype"
                value="boolean"
                v-model="ioDialogData.value.datatype"
              />
              <label for="datatypeBool">Boolean</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="datatypeInactive"
                name="datatype"
                value="inactive"
                v-model="ioDialogData.value.datatype"
              />
              <label for="datatypeInactive">inaktiv</label>
            </div>
          </div>
        </div>

        <div class="formgrid grid">
          <div class="field col">
            <Checkbox
              id="scale"
              name="scale"
              v-model="ioDialogData.value.scale"
              :binary="true"
            />
            <label for="scale">Werte-Skalierung aktivieren</label>
          </div>
        </div>

        <div v-if="ioDialogData.value.scale === true">
          <div class="formgrid grid">
            <div class="field col">
              <label for="rawMin">Rohwert-Skalierung Min</label>
              <InputNumber
                id="rawMin"
                v-model="ioDialogData.value.rawMin"
                integeronly
              />
            </div>
            <div class="field col">
              <label for="rawMax">Rohwert-Skalierung Max</label>
              <InputNumber
                id="rawMax"
                v-model="ioDialogData.value.rawMax"
                integeronly
              />
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field col">
              <label for="scaleMin">Endwert-Skalierung Min</label>
              <InputNumber
                id="scaleMin"
                v-model="ioDialogData.value.scaleMin"
              />
            </div>
            <div class="field col">
              <label for="scaleMax">Endwert-Skalierung Max</label>
              <InputNumber
                id="scaleMax"
                v-model="ioDialogData.value.scaleMax"
              />
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field col">
              <label>Steigung</label>
              <InputText id="_id" v-model="ioDialogData.value.pitch" readonly />
            </div>
            <div class="field col">
              <label>Offset</label>
              <InputText
                id="_id"
                v-model="ioDialogData.value.offset"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="formgrid grid">
          <div class="field col">
            <label>ID</label>
            <InputText id="_id" v-model="ioDialogData.source.datatype" readonly />
          </div>
        </div>

        <div class="formgrid grid">
          <div class="field col">
            <label>ID</label>
            <InputText id="_id" v-model="ioDialogData._id" readonly />
          </div>
        </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveProduct"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <b>{{ ioDialogData.nodeId }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProduct"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductsDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductsDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedProducts"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import store from "@/store";
// import tree from "@/data/tree.json";
// import ProductService from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";
// import socketioService from "@/services/socketioService.js";

export default {
  name: "ioConfig",
  data() {
    return {
      loading: false,
      fullSearch: false,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      ioDialogData: {
        source: {},
        metadata: {},
        value: {},
      },
      config_io: null,
      io_type: null,
      // opc_browse: {},
      opc_browse: [],
      opcTree: null,
      opcTreeServer: null,
      opcTreeSelection: null,
      opcTreeExpandedKeys: {},
      io_categories: null,
      io_types: null,
      io_unit: null,
      io_department: null,
      io_data: null,
      io_server: null,
      system_log: null,
      selectedProducts: null,
      selectedIOs: null,
      filters: null,
      submitted: false,
      intval_Value: null,
      io_value_factor: [
        { label: "1", value: 1 },
        { label: "10", value: 10 },
        { label: "100", value: 100 },
        { label: "1000", value: 1000 },
        { label: "10000", value: 10000 },
        { label: "100000", value: 100000 },
        { label: "1000000", value: 100000 },
      ],
      statuses: [
        { label: "Digital-Eingang", value: "DI" },
        { label: "Analog-Eingang", value: "AI" },
        { label: "Digital-Ausgang", value: "DO" },
        { label: "Analog-Ausgang", value: "AO" },
        { label: "Zähler", value: "CT" },
      ],
      units: [
        { label: "°C", value: "temperature" },
        { label: "m/s", value: "wind-ms" },
        { label: "km/h", value: "wind-km" },
        { label: "ppm", value: "ppm" },
        { label: "W/m2", value: "radiation" },
      ],
    };
  },
  productService: null,
  computed: {
    nodeEntries: function () {
      return store.getters.getValues;
    },
  },
  watch: {
    nodeEntries: {
      handler: function() {
        this.getParameterStatusIO();
      },
      deep: true
    }
  },
  created() {
    // this.productService = new ProductService();
    this.initFilters();
  },
  mounted() {
    // this.productService.getProducts().then((data) => (this.products = data));
    this.loading = true;
    this.registerSocketIoEvents();
    this.getParameter();
  },
  beforeUnmount() {
    this.unregisterSocketIoEvents();
  },
  methods: {
    resetDialogData() {
      this.ioDialogData = {
        source: {},
        metadata: {},
        value: {},
      };
      this.opcTreeSelection = null;
      this.opcTreeExpandedKeys = {};
    },
    getParameterStatusIO() {
      this.loading = true;

      if (this.nodeEntries.length > 0) {
        this.io_data = [];

        this.nodeEntries.forEach((element) => {
          this.io_data.push(element);
        });
        console.log(this.io_data);
        this.loading = false;
      }
      
      // SocketioService.getServerData("status", "io", (err, response) => {
      //   this.io_data = response;
      //   this.loading = false;
      // });

      // SocketioService.getStatusIO((response) => {
      //   this.io_data = response;
      //   this.loading = false;
      // });
    },
    // getParameterConfigIO() {
    //   SocketioService.getConfigIO((response) => {
    //     this.config_io = response;
    //   });
    // },
    getParameterConfigIO() {
      SocketioService.getServerData("config", "io", (err, response) => {
        this.config_io = response;
        console.log(this.config_io);
        this.config_io.types_icons.sort((a, b) => a.label.localeCompare(b.label));
      });

      SocketioService.getServerData("config", 'parameterObjects', (err, response) => {
        this.io_type = response;
      });
    },
    getParameterOpcBrowse() {
      console.log("OPC BROWSE START");
      // SocketioService.getOpcBrowse((response) => {
      //   this.opc_browse = response;
      //   console.log("OPC BROWSE:");
      //   console.log(response);
      // });
      SocketioService.getServerData("opc", "browse", (err, response) => {
        // this.opc_browse = response;
        this.opc_browse =
          response["root"]["RevPi Master"]["children"][8]["children"][1][
            "children"
          ];
        console.log("OPC BROWSE:");
        console.log(
          response["root"]["RevPi Master"]["children"][8]["children"][1][
            "children"
          ]
        );
      });
    },
    getOPCAppTree() {
      console.log(this.ioDialogData.source.server);
      console.log(this.opcTreeServer);
      // if (this.opcTree === null || this.ioDialogData.source.server !== this.opcTreeServer) {
      //   this.opcTree = null;
      //   this.opcTreeServer = null;
      //   SocketioService.getServerData("opc", "browse", (err, response) => {
      //     console.log(response, err);
      //     if (response !== undefined) {
      //       if (response["root"][this.ioDialogData.source.server]) {
      //         this.opcTree = response["root"][this.ioDialogData.source.server]["children"];
      //         this.opcTreeServer = this.ioDialogData.source.server;
      //         const result = this.getPath(this.opcTree, this.ioDialogData.source.path);
      //         if (Array.isArray(result)){
      //           if (result.length > 0) {
      //             for (let i=0; i<result.length; i++) {
      //               this.opcTreeExpandedKeys[result[i]] = true;
      //             }
      //           }
      //         }
      //       } else {
      //         this.opcTree = {};
      //       }
      //     } else {
      //       this.opcTree = {};
      //     }
      //   });
      // }
      
      // this.opcTree = [tree];
      console.log(this.opcTree);  
},
    getParameter() {
      this.getParameterConfigIO();
      // this.getParameterOpcBrowse();
      this.getParameterStatusIO();
      // this.getOPCAppTree();
    },
    registerSocketIoEvents() {
      SocketioService.socket.on("status_io_result", (data) => {
        console.log(data);
        if (data !== null) {
          this.getParameterStatusIO();
          // document not changed
          if (
            data.acknowledged &&
            data.matchedCount > 0 &&
            data.upsertedCount === 0 &&
            data.modifiedCount === 0
          ) {
            this.$toast.add({
              severity: "info",
              summary: "IO-Eintrag unverändert",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , keine Änderung notwendig " +
                "_obj.metadata.name,",
              life: 3000,
            });
            // document updated
          } else if (
            data.acknowledged &&
            data.matchedCount > 0 &&
            data.upsertedCount === 0 &&
            data.modifiedCount > 0
          ) {
            this.$toast.add({
              severity: "success",
              summary: "IO-Eintrag geändert",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , alle Änderungen wurden gespeichert " +
                "_obj.metadata.name,",
              life: 3000,
            });
            // document deleted
          } else if (data.acknowledged && data.deletedCount > 0) {
            this.$toast.add({
              severity: "success",
              summary: "IO-Eintrag gelöscht",
              detail: data.deletedCount + " Einträge erfolgreich gelöscht",
              life: 3000,
            });
            // document created
          } else if (data.acknowledged && data.insertedId !== null) {
            this.$toast.add({
              severity: "success",
              summary: "IO-Eintrag erstellt",
              detail:
                "Eintrag mit ID [" +
                data.insertedId.toString() +
                "] erfolgreich erstellt",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "IO-Eintrag nicht durchgeführt",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , Erstellt: " +
                data.upsertedCount +
                " [" +
                data.upsertedId +
                "]",
              life: 3000,
            });
          }
        }
      });
    },
    unregisterSocketIoEvents() {
      SocketioService.socket.removeListener("status_io_result");
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew(ioData) {
      this.resetDialogData();
      if (ioData !== null) {
        const _obj = JSON.parse(JSON.stringify(ioData));
        this.ioDialogData = _obj;
        this.ioDialogData._id = null;
      } else {
        this.ioDialogData.metadata.icon = "mif-meter";
        this.ioDialogData.value.value = 0.123;
        this.ioDialogData.value.unit = "°C";
        this.ioDialogData.value.factor = 1;
        this.ioDialogData.source.type = "opcua";
        this.ioDialogData.value.datatype = "integer";
      }
      this.submitted = false;
      this.getOPCAppTree();
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
      clearInterval(this.intval_Value);
      this.getParameterStatusIO();
    },
    saveProduct() {
      this.submitted = true;

      if (this.ioDialogData.metadata.name.trim()) {
        let _index = this.findIndexById(this.ioDialogData._id);
        if (_index >= 0) {
          // this.ioDialogData.nodeId = "ns=4;s=|var|CODESYS Control for Raspberry Pi MC SL.Application.PLC_ST." + this.ioDialogData.metadata.sensor;
          // this.ioDialogData.source.path = "ns=4;s=|var|CODESYS Control for Raspberry Pi MC SL.Application.PLC_ST." + this.ioDialogData.metadata.sensor;
          this.ioDialogData.nodeId = this.ioDialogData.source.path;
          this.io_data[_index] = this.ioDialogData;
          SocketioService.setConfigIO(this.io_data[_index]);
          // this.$toast.add({
          //   severity: "success",
          //   summary: "Successful",
          //   detail: "Product Updated",
          //   life: 3000,
          // });
        } else {
          this.ioDialogData.nodeId = this.ioDialogData.source.path;
          SocketioService.setConfigIO(this.ioDialogData);
          // this.product.id = this.createId();
          // this.product.code = this.createId();
          // this.product.image = "product-placeholder.svg";
          // this.products.push(this.product);
          // this.$toast.add({
          //   severity: "success",
          //   summary: "Successful",
          //   detail: "Product Created",
          //   life: 3000,
          // });
        }
        clearInterval(this.intval_Value);
        this.productDialog = false;
        this.resetDialogData();
      }
    },
    editProduct(ioData) {
      // this.ioDialogData = { ...ioData };
      let _opcSel = {};
      let _opcExp = {};
      // DEBUG
      // let _opcArr = {};

      this.getParameterStatusIO();
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      _opcSel[_obj.source.path] = true;
      _opcExp[_obj.source.path] = true;
      this.opcTreeSelection = _opcSel;
      // this.opcTreeExpandedKeys = _opcExp;
      // this.opcTreeExpandedKeys[_obj.source.path] = true;
      let thenode = {};
      thenode.key =
        "ns=4;s=|appo|CODESYS Control for Raspberry Pi MC SL.Application.Programs";

      // !!!!!!!!!! DEBUG !!!!!!!!!!!!
      // this.opcTree.forEach((entry) => {
      //   console.log(entry);
      //   if (entry.children !== undefined) {
      //     entry.children.forEach((entry2) => {
      //       if (entry2.children !== undefined) {
      //         entry2.children.forEach((entry3) => {
      //           if (entry3.key === _obj.source.path) {
      //             _opcArr = entry3.parent;
      //           }
      //         });
      //       }
      //     });
      //   }
      // });

      // console.log(this.opcTreeSelection);
      // _obj.parent.map((entry) => {
      //   if (entry !== undefined) {
      //     console.log(entry);
      //   }
      // });
      // this.expandNode(thenode);

      // !!!!!!!! DEBUG !!!!!!!!!
      // this.expandNode(_opcArr);

      // console.log("TREESELECTION: ", this.opcTreeSelection);
      // console.log("TREEEXPAND: ", this.opcTreeExpandedKeys);
      this.getOPCAppTree();
      this.productDialog = true;
      this.intval_Value = setInterval(this.updateOpenValue, 1000);
    },
    confirmDeleteProduct(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      // this.products = this.products.filter((val) => val.id !== this.product.id);
      let _index = this.findIndexById(this.ioDialogData._id);
      if (_index >= 0) {
        this.io_data[_index] = this.ioDialogData;
        SocketioService.removeValue(this.io_data[_index]);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Updated",
        //   life: 3000,
        // });
      } else {
        // this.product.id = this.createId();
        // this.product.code = this.createId();
        // this.product.image = "product-placeholder.svg";
        // this.products.push(this.product);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Created",
        //   life: 3000,
        // });
      }
      this.deleteProductDialog = false;
      this.resetDialogData();
      // this.$toast.add({
      //   severity: "success",
      //   summary: "Successful",
      //   detail: "Product Deleted",
      //   life: 3000,
      // });
    },
    updateOpenValue() {
      // let _index = this.findIndexById(this.ioDialogData._id);
      // if (_index >= 0) {
      //   // SocketioService.getStatusIO((response) => {
      //   //   this.ioDialogData.value.value = response[_index].value.value;
      //   // });

      // }
      SocketioService.getSingleValue(this.ioDialogData._id, (response) => {
        this.ioDialogData.value.value = response.value.value;
      });
    },
    onOpcTreeSelect(node) {
      this.ioDialogData.source.path = node.key;
      this.ioDialogData.source.datatype = node.datatype;
      this.ioDialogData.metadata.sensor = node.label;
      if (node.children !== undefined) {
        console.log(node.children);
      }
      this.$toast.add({
        severity: "success",
        summary: node.label,
        detail: node.key,
        life: 3000,
      });
    },
    expandAll() {
      for (let node of this.nodes) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    getPath(object, search) {
      if (object.key === search) return [object.key];
      else if ((object.children) || Array.isArray(object)) {
          let children = Array.isArray(object) ? object : object.children;
          for (let child of children) {
              let result = this.getPath(child, search);
              if (result) {
                  if (object.key )result.unshift(object.key);
                  return result;
              }
          }
      }
    },
    onServerChange() {
      this.getOPCAppTree();
    },
    findString(_str, _arr) {
      // let _index;
      // _index = _arr.indexOf(_str);
      // return _index;
      for (let i = 0; i < _arr.length; i++) {
        if (_arr[i]["value"] == _str) {
          return _arr[i]["label"];
        }
      }
    },
    getCategory(_cat) {
      let _catLabel;
      // console.log(_cat);
      if (this.config_io !== null) {
        _catLabel = this.findString(_cat, this.config_io.types_category);
        // console.log(this.config_io.types_category);
        // console.log(_catLabel);
        if (_catLabel) {
          return _catLabel;
        } else {
          return "???";
        }
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.io_data.length; i++) {
        if (this.io_data[i]._id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      SocketioService.removeValue(this.selectedIOs);
      this.deleteProductsDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'metadata.category': { value: null, matchMode: FilterMatchMode.IN },
        'metadata.department': { value: null, matchMode: FilterMatchMode.IN },
        'metadata.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'metadata.description': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'metadata.sensor': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'value.type': { value: null, matchMode: FilterMatchMode.IN },
        'value.unit': { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .p-multiselect-representative-option {
      display: inline-block;
      vertical-align: middle;
  }
</style>